<template>
  <v-container style="height:100%">
    <iframe src="http://58.211.191.32:9090/public/viewlist/62c30a2146afc5003e6273b4/61de80fa3f6359003ec98d55" frameborder="0" style="height:100%;width:100%"></iframe>
  </v-container>
</template>

<script>
export default {
  name: "Download"
}
</script>

<style scoped>

</style>